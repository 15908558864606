const ROUTES = {
    //SITEMAP
    homeRoute: () => "/",
    seedsRoute: () => "/seeds-how-to",
    yardRoute: () => "/yard-how-to",
    tasksRoute: () => "/tasks-how-to",
    analyticsRoute: () => "/analytics-how-to",
    accountRoute: () => "/account-how-to",
    //NOINDEX
    missingRoute: () => "/404",
    //EXTERNAL
    mainSiteRoute: () => "https://www.plannt.ca/",
    loginRoute: () => "https://app.plannt.ca/login",
    registerRoute: () => "https://app.plannt.ca/register",
    contactUsRoute: () => "https://www.plannt.ca/contact",
    termsOfUseRoute: () => "https://www.plannt.ca/termsofuse",
    privacyPolicyRoute: () => "https://www.plannt.ca/privacypolicy",
};

export default ROUTES;