import React from "react";
import { Helmet } from 'react-helmet';

import SignUpCta from './SignUpCta';
import ButtonPanel from './ButtonPanel';
import HomeFooter from './HomeFooter';
import Navbar from '../pages/Navbar';
import HeaderNav from './HeaderNav';
import Separator from '../pages/Separator';
import ROUTES from "../../App/routes";

export default function Home() {
  const introVidLink = "https://www.youtube.com/embed/l376lypKLY4?si=AvLF0DBl1OAJUjy0";
  const introVidDesc = "Get Excited About Food Garden Planning | plannt.ca Demo Video 2024";

  return (
    <section className="full-page">
      <Helmet>
        <meta charset="UTF-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>docs.plannt.ca | Tutorials for The Ultimate Garden Planning Tool for Square Foot Gardeners</title>
        <meta name="description" content="Let's grow food from seed! Learn how to use Plannt.ca to build an interactive map of your garden, generate your personalized schedule, and keep track of your tasks all growing season long."></meta>
        <link rel="canonical" href="https://docs.plannt.ca/"/>
      </Helmet>
      <Navbar/>
      <div className="main-page">
        <div className="tutorial-header" style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
          <HeaderNav/>
          <ButtonPanel/>
        </div>
        <div className="page-container">
          <div className="page-content">
            <h2>Welcome to <span className="plannt">plannt.ca</span>'s documentation site!</h2>
            <p>This is where you can find instructions and videos for how to get the most out of our garden planning platform.</p>
            <Separator index={1}/>
            <h2 style={{paddingTop:0}}>What is <span className="plannt">plannt.ca</span>?</h2>
            <p>We're an online garden planning platform made with love for home food growers.<br/>Check out this video for a sneak peek of some of the exciting features we offer.</p>
            <div itemProp="video" className='home-col' id="band">
              <iframe
                width="560"
                height="315"
                src={introVidLink}
                title={introVidDesc}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                frameBorder="0"
                allowFullScreen
                id="youtube"
              />
            </div>
            <Separator index={0}/>
            <div className="home-col" style={{paddingTop:0}}>
              <h2>How do I get started?</h2>
              <p style={{paddingBottom:"0.5em"}}>Easy peasy! Here are 5 steps that summarize how to use <span className="plannt">plannt.ca</span>:</p>
              <ol>
                <li>
                  <p><a href={ROUTES.registerRoute()}>Register for an account</a> and complete a super-quick onboarding session.</p>
                </li>
                <li>
                  <p>Add the <a href={ROUTES.seedsRoute()}>Seeds</a> you want to grow to your virtual library.</p>
                </li>
                <li>
                  <p>Map out your <a href={ROUTES.yardRoute()}>Yard</a> by defining the Patches where you have growing space.</p>
                </li>
                <li>
                  <p>Place Seeds in the Yard to create Crops, which will generate scheduled <a href={ROUTES.tasksRoute()}>Tasks</a> according to the Seed settings.</p>
                </li>
                <li>
                  <p>Go with the flow! Stay caught up on your Tasks, review your progress on the <a href={ROUTES.analyticsRoute()}>Analytics</a> dashboard, and enjoy all the tasty food.</p>
                </li>
              </ol>
              <p style={{paddingTop:"0.5em"}}>You could start getting your hands dirty right away, or check out the resources on this site to learn about each page.</p>
              <p>If you'd rather watch all of the tutorial videos on Youtube, check out <a href="https://www.youtube.com/playlist?list=PLO8Z-pytOoBtuBTEH3Fm4QtOdE-Zpaabw">our playlist</a>.</p>
            </div>
          </div>
          <SignUpCta/>
        </div>
        <HomeFooter/>
      </div>
    </section>
  );
}