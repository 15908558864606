import React from "react";

import carrot from '../img/veg_icons/carrot.png';
import cabbage from '../img/veg_icons/cabbage.png';
import cauliflower from '../img/veg_icons/cauliflower.png';
import tomato from '../img/veg_icons/tomato.png'
import squash from '../img/veg_icons/squash.png';

export default function Separator ({index}) {

  const picList = [carrot, cabbage, cauliflower, tomato, squash];

  //access picture from list with wraparound
  const getBottomPic = (indexNo) => {
    const mult = Math.floor(indexNo/picList.length);
    const accessInd = indexNo - (picList.length*mult);
    return picList[accessInd];
  };

  return (
    <div className="home-row center-row">
      <img src={getBottomPic(index)} alt="vegetable icon" className="veg-icons"/>
    </div>
  );
}