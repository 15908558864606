import React from "react";
import { Helmet } from 'react-helmet';

import Navbar from "./Navbar";
import ButtonPanel from "../landing/ButtonPanel";
import SignUpCta from "../landing/SignUpCta";
import Separator from "./Separator";
import HomeFooter from "../landing/HomeFooter";
import ROUTES from "../../App/routes";

export default function YardDocs () {
  const introVidLink = "https://www.youtube.com/embed/jF9UrWYuOUQ?si=-B9iUqSJXZYdIf2m";
  const introVidDesc = "How to Set Up Your Interactive Yard Map | plannt.ca";

  const createVidLink = "https://www.youtube.com/embed/VagBoigKv5U?si=M1lQThQvG0npAGtx";
  const createVidDesc = "How to Create Crops in Your Interactive Yard Map | plannt.ca";

  const editVidLink = "https://www.youtube.com/embed/d8IJXFv2bck?si=oU1xGmijlh0ukYQd";
  const editVidDesc = "How to Edit Crops in Your Interactive Yard Map | plannt.ca";

  const advVidLink = "https://www.youtube.com/embed/-uEzGq24MTs?si=oMoWNtEkT4g-9AY_";
  const advVidDesc = "Advanced Planning with Your Interactive Yard Map | plannt.ca";

  return (
    <section className="full-page">
      <Helmet>
        <meta charset="UTF-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Plan your Virtual Yard using plannt.ca | How to Use our Intelligent & Interactive Square Foot Map</title>
        <meta name="description" content="Let's get virtual! Maximize your space and growing season by learning how to use Plannt.ca's powerful garden mapping features."></meta>
        <link rel="canonical" href="https://docs.plannt.ca/yard-how-to"/>
      </Helmet>
      <Navbar/>
      <div className="main-page">
        <div className="tutorial-header">
          <h1 className="header-word">Yard Tutorials</h1>
          <ButtonPanel/>
        </div>
        <div className="page-container">
          <div className="page-content">
            <h2>Welcome to <span className="plannt">plannt.ca</span>'s Yard page tutorial series!</h2>
            <p>
              These quick videos show you how to use the interactive Yard map to plan out your growing season. <br/>
              We recommend that you watch the videos in the <a href={ROUTES.seedsRoute()}>Seeds</a> section before watching these.
            </p>
            <div className="home-col">
              <h2>1. Initial Yard Setup</h2>
              <p>
                How do I define the growing space in my Yard? <br/>
                Why should I apply labels to my growing space? <br/>
                How easy is it to reconfigure my growing space? <br/><br/>
                This tutorial video will introduce you to button modes, Patches, and how to apply Patch Group Labels in the Yard.
              </p>
              <div className='home-row spread-row one-row'>
              <div itemProp="video" className='home-col' id="band">
                <iframe
                  width="560"
                  height="315"
                  src={introVidLink}
                  title={introVidDesc}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  frameBorder="0"
                  allowFullScreen
                  id="youtube"
                />
              </div>
              </div>
            </div>
            <Separator index={4}/>
            <div className="home-col">
              <h2>2. Creating Crops</h2>
              <p>
                How does a Seed turn into a Crop? <br/>
                How will I know when to schedule different Seeds? <br/>
                How can I keep track of what I'm growing and where? <br/><br/>
                This tutorial video will take you through how to create crops, what timelines look like for different types of crops, and how to see what's happening in the future.
              </p>
              <div className='home-row spread-row one-row'>
                <div itemProp="video" className='home-col' id="band">
                  <iframe
                    width="560"
                    height="315"
                    src={createVidLink}
                    title={createVidDesc}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    frameBorder="0"
                    allowFullScreen
                    id="youtube"
                  />
                </div>
              </div>
            </div>
            <Separator index={5}/>
            <div className="home-col">
              <h2>3. Modifying Existing Crops</h2>
              <p>
                How do I move and expand the Crops I've already created? <br/>
                How do I delete Crops or reduce the amount of space they get? <br/>
                How does <span className="plannt">plannt.ca</span> prevent me from "double-booking" space in my Yard? <br/>
                How do I tell whether I'm placing crops according to companion planting recommendations? <br/><br/>
                This tutorial video will take you through how to edit the existing crops in your virtual Yard map.
              </p>
              <div className='home-row spread-row one-row'>
                <div itemProp="video" className='home-col' id="band">
                  <iframe
                    width="560"
                    height="315"
                    src={editVidLink}
                    title={editVidDesc}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    frameBorder="0"
                    allowFullScreen
                    id="youtube"
                  />
                </div>
              </div>
            </div>
            <Separator index={3}/>
            <div className="home-col">
              <h2>4. Advanced Yard Features</h2>
              <p>
                How does <span className="plannt">plannt.ca</span> help me keep track of crop rotation over the seasons? <br/>
                How can I use succession planting to maximize my growing space usage? <br/>
                How does <span className="plannt">plannt.ca</span> auto-schedule Crops? <br/><br/>
                This tutorial video covers the more advanced features of <span className="plannt">plannt.ca</span>'s interactive Yard map- for gardeners who really want to level up their gardening game.
              </p>
              <div className='home-row spread-row one-row'>
                <div itemProp="video" className='home-col' id="band">
                  <iframe
                    width="560"
                    height="315"
                    src={advVidLink}
                    title={advVidDesc}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    frameBorder="0"
                    allowFullScreen
                    id="youtube"
                  />
                </div>
              </div>
              <Separator index={4}/>
              <div className="home-col" style={{paddingTop:0}}>
                <h3>Ready for the next level?  Learn about <a href={ROUTES.tasksRoute()}>Tasks</a>.</h3>
              </div>
            </div>
          </div>
          <SignUpCta/>
        </div>
        <HomeFooter/>
      </div>
    </section>
  );
}