import React from "react";
import { Helmet } from 'react-helmet';

import Navbar from "./Navbar";
import ButtonPanel from "../landing/ButtonPanel";
import SignUpCta from "../landing/SignUpCta";
import Separator from "./Separator";
import HomeFooter from "../landing/HomeFooter";
import ROUTES from "../../App/routes";

export default function SeedsDocs () {
  const introVidLink = "https://www.youtube.com/embed/VPMxIdkbfyY?si=WpclpSvH7g5hauas"
  const introVidDesc = "How to Add a Seed to Your Virtual Library | plannt.ca";

  const editVidLink = "https://www.youtube.com/embed/V9QbXdnCPvM?si=XyLG1zTV_tM1lqS7";
  const editVidDesc = "How to Edit, Duplicate, and Delete Your Seeds | plannt.ca";

  const advVidLink = "https://www.youtube.com/embed/sFt8bhG_Apw?si=_ZdXAS2n8ZIVb8TQ";
  const advVidDesc = "How to Apply Ratings, Log Issues, and Share Your Seeds | plannt.ca";

  return (
    <section className="full-page">
      <Helmet>
        <meta charset="UTF-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Seed Management with plannt.ca | How to Maintain a Virtual Seed Library to track your Seed Inventory and Performance using our Online Garden Planner</title>
        <meta name="description" content="Not all seeds are the same! Learn how to use Plannt.ca to track seed instructions, on hand quantities, performance, issues, and much more."></meta>
        <link rel="canonical" href="https://docs.plannt.ca/seeds-how-to"/>
      </Helmet>
      <Navbar/>
      <div className="main-page">
        <div className="tutorial-header">
          <h1 className="header-word">Seeds Tutorials</h1>
          <ButtonPanel/>
        </div>
        <div className="page-container">
          <div className="page-content">
            <h2>Welcome to <span className="plannt">plannt.ca</span>'s Seeds page tutorial series!</h2>
            <p>
              These quick videos will take you from the greenest of beginners to Pro Gardener in how to manage your virtual Seed library. <br/>
              We recommend watching the videos in the order shown, and trying things out for yourself as you learn.
            </p>
            <div className="home-col">
              <h2>1. Creating Seeds</h2>
              <p>
                How do I start adding Seeds to my virtual library? <br/>
                How can I track the on-hand quantity of my Seeds? <br/>
                How will I know what settings to apply when creating Seeds? <br/><br/>
                This introduction video will take you through how to set your personal growing preferences and capture important growing instructions for your Seeds.
              </p>
              <div className='home-row spread-row one-row'>
                <div itemProp="video" className='home-col' id="band">
                  <iframe
                    width="560"
                    height="315"
                    src={introVidLink}
                    title={introVidDesc}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    frameBorder="0"
                    allowFullScreen
                    id="youtube"
                  />
                </div>
              </div>
            </div>
            <Separator index={1}/>
            <div className="home-col">
              <h2>2. Edit, Duplicate, or Delete a Seed</h2>
              <p>
                Once I create a Seed, can I change it?  <br/>
                What is the fastest way to create more Seeds?  <br/>
                How do I delete a Seed, and what happens when I do that?  <br/><br/>
                This video gives tips on all the above and more to help you keep your virtual Seed library up to date.
              </p>
              <div className='home-row spread-row one-row'>
                <div itemProp="video" className='home-col' id="band">
                  <iframe
                    width="560"
                    height="315"
                    src={editVidLink}
                    title={editVidDesc}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    frameBorder="0"
                    allowFullScreen
                    id="youtube"
                  />
                </div>
              </div>
            </div>
            <Separator index={2}/>
            <div className="home-col">
              <h2>3. Rate, Share, and Filter Seeds</h2>
              <p>
                How do I keep track of which Seeds I like the most?  <br/>
                Where can I add reminders to myself about specific issues or care instructions for my Seeds?  <br/>
                Can I share Seed information with other gardeners?  <br/><br/>
                This tutorial video covers additional features of the Seeds page that make it easier to manage your virtual Seed library as it grows.
              </p>
              <div className='home-row spread-row one-row'>
                <div itemProp="video" className='home-col' id="band">
                  <iframe
                    width="560"
                    height="315"
                    src={advVidLink}
                    title={advVidDesc}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    frameBorder="0"
                    allowFullScreen
                    id="youtube"
                  />
                </div>
              </div>
            </div>
            <Separator index={3}/>
            <div className="home-col" style={{paddingTop:0}}>
              <h3>Ready for the next level?  Learn about the <a href={ROUTES.yardRoute()}>Yard</a>.</h3>
            </div>
          </div>
          <SignUpCta/>
        </div>
        <HomeFooter/>
      </div>
    </section>
  );
}