import { NavLink } from 'react-router-dom';
import React from "react";

import ROUTES from '../../App/routes';

export default function Logo() {
  const logo = process.env.PUBLIC_URL+'/logo.svg';
  
  return (
    <NavLink to={ROUTES.homeRoute()}>
      <div itemProp="brand" itemScope itemType="https://schema.org/Brand" className="icon-top-left">
          <div className="tm-box">
            <img itemProp="logo" src={logo} className="icon" id="topleft-icon" alt="plannt logo" />
            <span className="TM">TM</span>
          </div>
          <div className="tm-box">
            <h1 itemProp="name" className="logo-text-home"><span className='subdomain'>docs.</span>plannt.ca</h1>
          </div>
      </div>
    </NavLink>
  );
};
