import { NavLink } from 'react-router-dom';
import React from "react";

import ROUTES from '../../App/routes';

export default function ButtonPanel() {
  return (
    <div className = "home-button-panel">
      <NavLink to={ROUTES.loginRoute()} className={(navData)=>navData.isActive ? "active" : "inactive"}>
        <input type="submit" id="login-inactive" value="log in"/>
      </NavLink>
      <NavLink to={ROUTES.registerRoute()} className={(navData)=>navData.isActive ? "active" : "inactive"}>
        <input id="register-btn" type="submit" value="sign up"/>
      </NavLink>
    </div>
  );
};
