import './App.css';
import React from "react";
import ReactGA from "react-ga4";
import { getCookieConsentValue } from "react-cookie-consent";
import {
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import ROUTES from "./routes";

import Home from "../components/landing/Home";
import MissingPage from '../components/landing/MissingPage';
import AccountDocs from '../components/pages/AccountDocs';
import SeedsDocs from '../components/pages/SeedsDocs';
import TasksDocs from '../components/pages/TasksDocs';
import YardDocs from '../components/pages/YardDocs';
import AnalyticsDocs from '../components/pages/AnalyticsDocs';

export default function App() {
  if(process.env.NODE_ENV !== "development"){
    const isConsent = getCookieConsentValue();
    if(isConsent !== "true") {
      ReactGA.gtag("consent", "default", {
        ad_storage: "denied",
        ad_user_data: "denied",
        ad_personalization: "denied",
        analytics_storage: "denied",
      });
    }
    ReactGA.initialize("G-2768HX3GDP");
  }
  
  return (
    <Routes>
      <Route path={ROUTES.homeRoute()} element={<Home/>} />
      <Route path={ROUTES.accountRoute()} element={<AccountDocs/>} />
      <Route path={ROUTES.seedsRoute()} element={<SeedsDocs/>} />
      <Route path={ROUTES.tasksRoute()} element={<TasksDocs/>} />
      <Route path={ROUTES.yardRoute()} element={<YardDocs/>} />
      <Route path={ROUTES.analyticsRoute()} element={<AnalyticsDocs/>} />
      <Route path={ROUTES.missingRoute()} element={<MissingPage/>} />
      <Route path="*" element={<Navigate to={ROUTES.missingRoute()} />} />
    </Routes>
  );
}
