import React from "react";
import { Helmet } from 'react-helmet';

import Navbar from "./Navbar";
import ButtonPanel from "../landing/ButtonPanel";
import SignUpCta from "../landing/SignUpCta";
import HomeFooter from "../landing/HomeFooter";

export default function AnalyticsDocs () {
  const introVidLink = "https://www.youtube.com/embed/VOU7nP2LUXA?si=lPgMiLTYNOUnLyyF";
  const introVidDesc = "Take a Tour of Your Garden Analytics Dashboard | plannt.ca";

  return (
    <section className="full-page">
      <Helmet>
        <meta charset="UTF-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Garden Analytics with plannt.ca | How to Supercharge your Growing Season using our Helpful Dashboard</title>
        <meta name="description" content="All of your growing insights at a glance! Learn how to use Plannt.ca's powerful analytics dashboard to check in on your garden's performance and improve year after year."></meta>
        <link rel="canonical" href="https://docs.plannt.ca/analytics-how-to"/>
      </Helmet>
      <Navbar/>
      <div className="main-page">
        <div className="tutorial-header">
          <h1 className="header-word">Analytics Tutorial</h1>
          <ButtonPanel/>
        </div>
        <div className="page-container">
          <div className="page-content">
            <h2>Welcome to a tour of <span className="plannt">plannt.ca</span>'s Analytics page!</h2>
            <p>
              Learn how to gather insights from your growing season data and level up your gardening game. <br/>
              To keep up to date on your progress, we recommend reviewing your Analytics dashboard each time you log in to the platform.
            </p>
            <div className="home-col">
              <h2>Exploring your Analytics Dashboard</h2>
              <p>
                Where can I find a high-level summary of my progress over the growing season? <br/>
                How do I know whether my garden is on track and performing well? <br/>
                How can I learn from my previous growing seasons to keep improving? <br/><br/>
                This overview video will take you through the entire Analytics page and explain how the different charts and statistics help to quantify your food garden's productivity.
              </p>
              <div className='home-row spread-row one-row'>
              <div itemProp="video" className='home-col' id="band">
                <iframe
                  width="560"
                  height="315"
                  src={introVidLink}
                  title={introVidDesc}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  frameBorder="0"
                  allowFullScreen
                  id="youtube"
                />
              </div>
              </div>
            </div>
          </div>
          <SignUpCta/>
        </div>
        <HomeFooter/>
      </div>
    </section>
  );
}