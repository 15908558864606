import React from "react";
import { Helmet } from 'react-helmet';

import Navbar from "./Navbar";
import ButtonPanel from "../landing/ButtonPanel";
import SignUpCta from "../landing/SignUpCta";
import Separator from "./Separator";
import HomeFooter from "../landing/HomeFooter";
import ROUTES from "../../App/routes";

export default function TasksDocs () {
  const introVidLink = "https://www.youtube.com/embed/NbOzkr3SuYM?si=rFKOQ6d12CUtq8aS";
  const introVidDesc = "Introduction to Your Gardening Tasks | plannt.ca";

  const detailVidLink = "https://www.youtube.com/embed/MCuTAlSu2uw?si=GdQOJKZVTZ-wq78p";
  const detailVidDesc = "How to Complete Each Type of Gardening Task | plannt.ca";

  return (
    <section className="full-page">
      <Helmet>
        <meta charset="UTF-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Garden Task Management with plannt.ca | How to stay on top of your Garden Planning</title>
        <meta name="description" content="Take food growing to task! Learn how to use Plannt.ca to manage important garden tasks from seed to harvest."></meta>
        <link rel="canonical" href="https://docs.plannt.ca/tasks-how-to"/>
      </Helmet>
      <Navbar/>
      <div className="main-page">
        <div className="tutorial-header">
          <h1 className="header-word">Tasks Tutorials</h1>
          <ButtonPanel/>
        </div>
        <div className="page-container">
          <div className="page-content">
            <h2>Welcome to <span className="plannt">plannt.ca</span>'s Tasks tutorial series!</h2>
            <p>
              These quick videos show you how to work with scheduled Tasks for each of your Crops to support them through their time in your garden. <br/>
              We recommend that you watch the videos in the <a href={ROUTES.seedsRoute()}>Seeds</a> and <a href={ROUTES.yardRoute()}>Yard</a> sections before watching these.
            </p>
            <div className="home-col">
              <h2>1. Introduction to Tasks</h2>
              <p>
                How do I navigate the Tasks page and change the date filters? <br/>
                Where do Tasks come from and how are they scheduled? <br/>
                What Task statuses are possible and when do they change? <br/><br/>
                This tutorial video will introduce you to the Tasks page and explain how it works.
              </p>
              <div className='home-row spread-row one-row'>
                <div itemProp="video" className='home-col' id="band">
                  <iframe
                    width="560"
                    height="315"
                    src={introVidLink}
                    title={introVidDesc}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    frameBorder="0"
                    allowFullScreen
                    id="youtube"
                  />
                </div>
              </div>
            </div>
            <Separator index={3}/>
            <div className="home-col">
              <h2>2. Details on Task Flows</h2>
              <p>
                How do Tasks change depending on my Seed settings? <br/>
                What is the sequence of Tasks for Crops that start outdoors compared to those that start indoors? <br/>
                How do I complete each type of Task? <br/><br/>
                This tutorial video will take you through the finer details of how to start and complete Tasks, as well as the work that is involved in each type of task.  
                We also cover how to change task start or completion dates, update your seed inventory, and add harvest logs as you complete tasks.
              </p>
              <div className='home-row spread-row one-row'>
                <div itemProp="video" className='home-col' id="band">
                  <iframe
                    width="560"
                    height="315"
                    src={detailVidLink}
                    title={detailVidDesc}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    frameBorder="0"
                    allowFullScreen
                    id="youtube"
                  />
                </div>
              </div>
            </div>
            <Separator index={4}/>
            <div className="home-col">
              <h2>3. Additional Resources</h2>
              <p>
                Check out our blog posts below for more details on how to complete important gardening tasks in the real world.
              </p>
              <ul>
                <li><a className="blog-link" href="https://plannt.medium.com/gardening-guide-starting-seeds-indoors-9132f0d13de9" rel="noreferrer nofollow" target="_blank">How to Start Your Seedlings Indoors</a></li>
                <li><a className="blog-link" href="https://plannt.medium.com/gardening-guide-how-to-harden-off-seedlings-in-7-days-cfe9bc8aa21e" rel="noreferrer nofollow" target="_blank">How to Harden Off Your Seedlings</a></li>
                <li><a className="blog-link" href="https://plannt.medium.com/gardening-guide-transplanting-seedlings-outdoors-f7e7a64291a1" rel="noreferrer nofollow" target="_blank">How to Transplant Your Seedlings Outdoors</a></li>
                <li><a className="blog-link" href="https://plannt.medium.com/gardening-guide-sowing-seeds-outdoors-51944e8e1459" rel="noreferrer nofollow" target="_blank">How to Sow Your Seeds Directly Outdoors</a></li>
                <li><a className="blog-link" href="https://plannt.medium.com/gardening-guide-thinning-seedlings-d6c620232387" rel="noreferrer nofollow" target="_blank">How to Thin Your Seedlings</a></li>
                <li><a className="blog-link" href="https://plannt.medium.com/gardening-guide-harvesting-your-crops-2a7f39c6a72" rel="noreferrer nofollow" target="_blank">How to Harvest Your Crops</a></li>
              </ul>
            </div>
            <Separator index={5}/>
            <div className="home-col" style={{paddingTop:0}}>
              <h3>Ready for the next level?  Learn about the <a href={ROUTES.analyticsRoute()}>Analytics</a> Dashboard.</h3>
            </div>
          </div>
          <SignUpCta/>
        </div>
        <HomeFooter/>
      </div>
    </section>
  );
}