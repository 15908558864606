import React from "react";
import { Helmet } from 'react-helmet';

import Navbar from "./Navbar";
import ButtonPanel from "../landing/ButtonPanel";
import SignUpCta from "../landing/SignUpCta";
import Separator from "./Separator";
import HomeFooter from "../landing/HomeFooter";

export default function AccountDocs () {
  return (
    <section className="full-page">
      <Helmet>
        <meta charset="UTF-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Your plannt.ca Account | How to maintain your settings & information in our Garden Planning Tool</title>
        <meta name="description" content="There's nobody else like you! Learn how to update your Account details in Plannt.ca, manage your subscription, and refer others to the platform."></meta>
        <link rel="canonical" href="https://docs.plannt.ca/account-how-to"/>
      </Helmet>
      <Navbar/>
      <div className="main-page">
        <div className="tutorial-header">
          <h1 className="header-word">Account Management</h1>
          <ButtonPanel/>
        </div>
        <div className="page-container">
          <div className="page-content">
            <h2>What can I do with a <span className="plannt">plannt.ca</span> account?</h2>
            <p>
              We're a garden planning platform made by home food growers, for home food growers.<br/>
              The tools and features of <span className="plannt">plannt.ca</span> help you to go further with the space, time, and skills you currently have.<br/><br/>
              Some of our awesome features include:<br/>
            </p>
            <ol>
                <li>A virtual seed library to keep track of your seed inventory, growing preferences, and seed performance.</li>
                <li>An interactive yard map that makes it fun to plan out your season while maximizing space usage.</li>
                <li>Weekly task summary emails to keep you on schedule for the season.</li>
                <li>A personalized analytics dashboard to review your progress live.</li>
              </ol>
            <p style={{paddingTop:"0.5em"}}>and much more!</p>
            <div className="home-col">
              <h2>Updating your Account Details</h2>
              <p>On the Account page, you can update your location, yard size, and frost dates by simply editing the values in the fields and clicking "Save Changes".</p>
            </div>
            <Separator index={0}/>
            <div className="home-col">
              <h2>Maintaining Your Subscription</h2>
              <p>
                At any time during your free trial, you can start a paid subscription by clicking the "Start Paid Subscription" button on your Account page.<br/><br/>
                Once you are a paid subscriber (aka Pro Gardener), the Subscription portal can be accessed at any time by clicking the "Manage Subscription" button on your Account page.
              </p>
            </div>
            <Separator index={1}/>
            <div className="home-col">
              <h2>Referring Friends</h2>
              <p>
                To refer a friend to the platform, enter their email in the Referrals section of your account page.<br/>
                If the gardener you refer becomes a paid subscriber, you both get a discount!
              </p>
            </div>
          </div>
          <SignUpCta/>
        </div>
        <HomeFooter/>
      </div>
    </section>
  );
}