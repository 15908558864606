import React from "react";

import ROUTES from "../../App/routes";

export default function SignUpCta() {
  const logo =  process.env.PUBLIC_URL+'/logo.svg';

  return (
    <div className='home-end'>
      <div className="icon-top-left">
        <div className="tm-box">
          <img src={logo} id="bottom-icon" className="icon" alt="plannt logo" style={{maxWidth: "4em"}}/>
          <span className="TM">TM</span>
        </div>
      </div>
      <h4>Ready to get growing?</h4>
      <a href={ROUTES.registerRoute()}>
        <input type="submit" value="sign up" />
      </a>
    </div>
  );
}