import account_icon from '../img/custom_icons/account_icon.png';
import analytics_icon from '../img/custom_icons/analytics_icon.png';
import seeds_icon from '../img/custom_icons/seeds_icon.png';
import tasks_icon from '../img/custom_icons/tasks_icon.png';
import yard_icon from '../img/custom_icons/yard_icon.png';

import light_account_icon from '../img/custom_icons/account_icon_white.png';
import light_analytics_icon from '../img/custom_icons/analytics_icon_white.png';
import light_seeds_icon from '../img/custom_icons/seeds_icon_white.png';
import light_tasks_icon from '../img/custom_icons/tasks_icon_white.png';
import light_yard_icon from '../img/custom_icons/yard_icon_white.png';

import { NavLink } from 'react-router-dom';

import ROUTES from "../../App/routes";
import Logo from '../landing/Logo';

export default function Navbar() {

    return (
        <nav>
            <Logo/>
            <div className="navBar">
                <div className="nav-top" id='nav-list'>
                    <NavLink to={ROUTES.seedsRoute()} className={(navData)=>navData.isActive ? "active" : "inactive"}>
                        <div className="nav-list-item">
                            <img src={seeds_icon} className="nav-icon" alt="seeds" />
                            <img src={light_seeds_icon} className="light-nav-icon" alt="seeds" />
                            <h1 className="nav-text">Seeds</h1>
                        </div>
                    </NavLink>
                    <NavLink to={ROUTES.yardRoute()} className={(navData)=>navData.isActive ? "active" : "inactive"}>
                        <div className="nav-list-item">
                            <img src={yard_icon} className="nav-icon" alt="yard" />
                            <img src={light_yard_icon} className="light-nav-icon" alt="yard" />
                            <h1 className="nav-text">Yard</h1>
                        </div>
                    </NavLink>
                    <NavLink to={ROUTES.tasksRoute()} className={(navData)=>navData.isActive ? "active" : "inactive"}>
                        <div className="nav-list-item">
                            <img src={tasks_icon} className="nav-icon" alt="tasks list" />
                            <img src={light_tasks_icon} className="light-nav-icon" alt="tasks list" />
                            <h1 className="nav-text">Tasks</h1>
                        </div>
                    </NavLink>
                    <NavLink to={ROUTES.analyticsRoute()} className={(navData)=>navData.isActive ? "active" : "inactive"}>
                        <div className="nav-list-item">
                            <img src={analytics_icon} className="nav-icon" alt="analytics" />
                            <img src={light_analytics_icon} className="light-nav-icon" alt="analytics" />
                            <h1 className="nav-text">Analytics</h1>
                        </div>
                    </NavLink>
                </div>
                <div className="nav-bottom" id='nav-list'>
                    <NavLink to={ROUTES.accountRoute()} className={(navData)=>navData.isActive ? "active" : "inactive"}>
                        <div className="nav-list-item">
                            <img src={account_icon} className="nav-icon" alt="account" />
                            <img src={light_account_icon} className="light-nav-icon" alt="account" />
                            <h1 className="nav-text">Account</h1>
                        </div>
                    </NavLink>
                </div>
            </div>
        </nav>
    );
}
