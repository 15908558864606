import React from "react";

import ROUTES from '../../App/routes';
import Socials from './Socials';
import CookieConsentBanner from "./CookieConsentBanner";

export default function HomeFooter() {
  return (
    <div className='footer-span'>
      <div className='footer-panel'>
        <Socials/>
        <ul className="footer-list" id="word-row">
          <li><a href={ROUTES.contactUsRoute()}>Contact Us</a></li>
          <li><a href={ROUTES.termsOfUseRoute()}>Terms of Use</a></li>
          <li><a href={ROUTES.privacyPolicyRoute()}>Privacy Policy</a></li>
        </ul>
        <div className="copyright-msg"><p>Copyright &copy; 2024 Plannt Solutions.  All rights reserved.</p></div>
        <div><p>icons by <a target="_blank" rel='noreferrer noopener nofollow' href="https://icons8.com">Icons8</a></p></div>
      </div>
      <CookieConsentBanner/>
    </div>
  );
}
