import React, { useState, useRef, useEffect } from "react";

import hamburger from '../../components/img/custom_icons/hamburger.png';
import ROUTES from '../../App/routes';

export default function HeaderNav() {
  const [isExpanded, setIsExpanded] = useState(true);

  const buttonRef = useRef();
  const menuRef = useRef();

  const handleMouseLeave = (e) => {
    setTimeout(() => setIsExpanded(!isExpanded), 10);
  };

  const handleClickOutside = (e) => {
    if(!menuRef.current.contains(e.target) && !buttonRef.current.contains(e.target)){
      setIsExpanded(true);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <div className="left-top-container">
      <button ref={buttonRef} className="top-menu-burger" onClick={()=>{setIsExpanded(!isExpanded)}}>
        <img src={hamburger} alt="hamburger menu"></img>
      </button>
      <ol itemScope itemType="https://schema.org/BreadcrumbList"
        ref={menuRef} 
        className={isExpanded ? "top-menu-panel expanded" : "top-menu-panel"} 
        onMouseLeave={!isExpanded ? ()=>{handleMouseLeave()}:undefined}
      >
        <li itemScope itemProp="itemListElement" itemType="https://schema.org/ListItem"
          className="top-menu-button"
          style={{minWidth:"8.5em"}}
          onClick={()=>{setIsExpanded(!isExpanded)}}
        >
          <a href={ROUTES.mainSiteRoute()} target="_blank" rel="noreferrer noopener" itemProp="item">
            <span itemProp="name">Main Site</span>
          </a>
          <meta itemProp="position" content="1" />
        </li>
        <li itemScope itemProp="itemListElement" itemType="https://schema.org/ListItem"
          className="top-menu-button" onClick={()=>{setIsExpanded(!isExpanded)}}
        >
          <a href="https://plannt.medium.com" target="_blank" rel="noreferrer noopener" itemProp="item">
            <span itemProp="name">Blog</span>
            <meta itemProp="position" content="2" />
          </a>
        </li>
      </ol>
    </div>
  );
}